<template>
<div id="sidebar-demo">
    <div class="vx-row">
        <div class="vx-col w-full sm:w-full md:w-full lg:w-1/4 xl:w-1/5 cursor">
            <div class="vx-col w-full mb-base cursor" @click="seeRegistration('PendingRegistration')" v-if="widgetPendingRegistration">
                <statistics-card-line hideChart icon="BarChart2Icon" :statistic="widgetPendingRegistration.metricCount" :statisticTitle="widgetPendingRegistration.metric" />
            </div>
            <div class="vx-col w-full mb-base cursor" @click="seeRegistration('ProcessingRegistration')" v-if="widgetProcessingRegistration">
                <statistics-card-line hideChart icon="BarChart2Icon" :statistic="widgetProcessingRegistration.metricCount" :statisticTitle="widgetProcessingRegistration.metric" />
            </div>
            <div class="vx-col w-full mb-base cursor" @click="seeRegistration('ActiveRegistration')" v-if="widgetActiveRegistration">
                <statistics-card-line hideChart icon="BarChart2Icon" :statistic="widgetActiveRegistration.metricCount" :statisticTitle="widgetActiveRegistration.metric" />
            </div>
            <div class="vx-col w-full mb-base cursor" @click="seeRegistration('blacklist')" v-if="widgetBlackList">
                <statistics-card-line hideChart icon="BarChart2Icon" :statistic="`${this.widgetBlackList.blackListedCount} / ${this.widgetBlackList.revokedCount}`" statisticTitle="Black List Vehicle" />
            </div>
            <div class="vx-col w-full mb-base cursor">
                <widget-promo-code></widget-promo-code>
            </div>
        </div>
        <div class="vx-col w-full sm:w-full md:w-full lg:w-4/5 xl:w-4/5 cursor">
            <div class="vx-row">
                <div class="vx-col w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 m-0 cursor">
                    <widget-customer-postcode-count></widget-customer-postcode-count>
                </div>
                <div class="vx-col w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 m-0 cursor">
                    <widget-season-pass-by-postcode></widget-season-pass-by-postcode>
                </div>
            </div>
            <div class="vx-row">
                <div class="vx-col w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 m-0 cursor">
                    <widget-visit-by-time class="mt-base"></widget-visit-by-time>
                </div>
                <div class="vx-col w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 m-0 cursor">
                    <widget-visity-by-date class="mt-base"></widget-visity-by-date>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    analyticsData
} from "../store/api/analytics";
import VueApexCharts from 'vue-apexcharts'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import WidgetPromoCode from './Widgets/widgetPromoCode.vue';
import widgetVisitByTime from './Widgets/widgetVisitByTime.vue';
import WidgetVisityByDate from './Widgets/widgetVisityByDate.vue';
import WidgetCustomerPostcodeCount from './Widgets/widgetCustomerPostcodeCount.vue';
import WidgetSeasonPassByPostcode from './Widgets/WidgetSeasonPassByPostcode.vue';

export default {
    data() {
        return {
            widgetPendingRegistration: null,
            widgetActiveRegistration: null,
            widgetProcessingRegistration: null,
            widgetBlackList: null
        }
    },
    components: {
        StatisticsCardLine,
        VueApexCharts,
        WidgetPromoCode,
        widgetVisitByTime,
        WidgetVisityByDate,
        WidgetCustomerPostcodeCount,
        WidgetSeasonPassByPostcode,
    },
    async created() {
        await this.loadAnalyticsData();
        await this.loadActiveAnalyticsData();
        await this.loadProcessingAnalyticsData();
        await this.loadBlackListVehicle();
    },
    methods: {
        async loadAnalyticsData() {
            this.widgetPendingRegistration = await analyticsData.searchAnalyticPendingRegistration();
        },
        async loadActiveAnalyticsData() {
            this.widgetActiveRegistration = await analyticsData.searchAnalyticActiveRegistration();
        },
        async loadProcessingAnalyticsData() {
            this.widgetProcessingRegistration = await analyticsData.searchAnalyticProcessingRegistration();
        },
        async loadBlackListVehicle() {
            this.widgetBlackList = await analyticsData.searchAnalyticBlacklist();
        },
        seeRegistration(path) {
            this.$router.push(`/admin/${path}`);
        }
    }
}
</script>
