<template>
<vx-card title="Customers">
    <div slot="actions" class="flex justify-center">
        <vs-tooltip text="Export to Excel" position="bottom">
            <vs-button icon-pack="feather" icon="icon-download" class="m-0" color="success" @click="downloadexcel"></vs-button>
        </vs-tooltip>
    </div>
    <vue-apex-charts class="mt-6 mb-8" :key="componentKey" type="donut" height="200" :options="computedChartData.chartOptions" :series="computedChartData.series" />
</vx-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import {
    analyticsData
} from "@/store/api/analytics";
import {
    reportsData
} from "@/store/api/report";

export default {
    components: {
        VueApexCharts
    },
    data() {
        return {
            componentKey: 1,
            chartSeries: [],
            chartLabel: [],
        }
    },
    computed: {
        computedChartData() {
            const columnChart = {
                series: this.chartSeries,
                chartOptions: {
                    labels: this.chartLabel,
                    dataLabels: {
                        enabled: false
                    },
                    legend: {
                        show: true
                    },
                    chart: {
                        //offsetY: 30,
                        type: 'donut',
                        toolbar: {
                            show: false
                        }
                    },
                    stroke: {
                        width: 0
                    },
                    colors: ['#7961F9', '#FF9F43', '#EA5455'],
                    fill: {
                        type: 'gradient',
                        gradient: {
                            gradientToColors: ['#9c8cfc', '#FFC085', '#f29292']
                        }
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            // legend: {
                            //   position: 'bottom'
                            // }
                        }
                    }]
                }
            }

            return columnChart;
        }
    },
    async created() {
        await this.loadChartData();
    },
    methods: {
        async loadChartData() {
            this.componentKey + 1;
            var chartData = await analyticsData.searchAnalyticsCustomerCountByPostcode();
            chartData.forEach(element => {
                this.chartSeries.push(element.preferenceCount)
                this.chartLabel.push(element.preference)
            });
        },
        async downloadexcel() {
            const reportParameter = {
                productId: this.selectedProduct
            };

            let data = await reportsData.customerReportExportToExcel(reportParameter);
            window.open(data.url, '_blank').focus();
        },
    }
}
</script>
