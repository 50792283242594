<template>
<vx-card title="Visits" customTitle="By Date">
    <template slot="actions">
        <flat-pickr v-model="fromDate" placeholder="From Date" @on-change="onFromChange" />
        <flat-pickr v-model="toDate" placeholder="To Date" @on-change="onToChange" />
    </template>
    <vue-apex-charts type="bar" height="240" :key="componentKey" :options="computedChartData.chartOptions" :series="computedChartData.series"></vue-apex-charts>
</vx-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

import moment from 'moment'

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import {
    analyticsData
} from "@/store/api/analytics";

export default {
    components: {
        flatPickr,
        VueApexCharts
    },
    data() {
        return {
            componentKey: 1,
            configFromdateTimePicker: {
                maxDate: null,
                altFormat: 'd-m-Y',
                altInput: true,
                dateFormat: 'Y-m-d',
            },
            configTodateTimePicker: {
                minDate: null,
                altFormat: 'd-m-Y',
                altInput: true,
                dateFormat: 'Y-m-d',
            },
            fromDate: null,
            toDate: null,
            chartData: [],
            categories: [],
            categoryData: [],
            themeColors: ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E'],
        }
    },
    computed: {
        computedChartData() {
            const columnChart = {
                series: [{
                    name: 'Time Of Day',
                    data: this.categoryData
                }],
                chartOptions: {
                    colors: this.themeColors,
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            endingShape: 'rounded',
                            columnWidth: '55%',
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: this.categories
                    },
                    yaxis: {
                        title: {
                            text: 'Number of Visits'
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return val + " Visits"
                            }
                        }
                    }
                }
            }

            return columnChart;
        }
    },
    async created() {
        this.fromDate = moment().add(-7, 'days').format();
        this.toDate = moment().format();
        await this.loadChartData();
    },
    methods: {
        async loadChartData() {

            this.componentKey + 1;
            let chartParam = {
                startDate: this.fromDate,
                endDate: this.toDate
            }

            this.chartData = await analyticsData.searchChartVisitByDate(chartParam);
            if (this.chartData) {
                this.categories = this.chartData.map(x => x.dateOfEntry);
                this.categoryData = this.chartData.map(x => x.countOfEntry);
                //console.log("🚀 ~ file: widgetVisitByTime.vue ~ line 95 ~ loadChartData ~ categories", categories)
                // this.categories = categories;
                // this.columnChart.series[0].data = counts;
            }
        },
        async onFromChange(selectedDates, dateStr, instance) {
            this.$set(this.configTodateTimePicker, "minDate", dateStr);
            const date = new Date(dateStr);
            await this.loadChartData();
        },
        async onToChange(selectedDates, dateStr, instance) {
            this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
            await this.loadChartData();
        },
    }
}
</script>
